import { Controller } from "@hotwired/stimulus"
import { orderBy } from "lodash-es"
import { useDebounce } from "stimulus-use"
import { isMobile } from "../../../../ui/static_src/ui/utils/breakpoints.js"
import { fetchUrl } from "../../../../ui/static_src/ui/utils/fetch"

class Briefings extends Controller {
  goToTop(event) {
    event.preventDefault()
    window.scrollTo({ top: 0, behavior: "smooth" })
    history.replaceState(null, "", location.pathname)
  }
}

class Briefing extends Controller {
  static targets = ["section", "visibleSection"]
  static intersectionRootMargin = "-80px 0px -200px 0px"
  intersectionObserver = null

  connect() {
    if (this.hasSectionTarget) {
      this.intersectionObserver = new IntersectionObserver(
        (entries) => entries.forEach((entry) => this.intersect(entry)),
        { rootMargin: Briefing.intersectionRootMargin },
      )
      this.sectionTargets.forEach((section) =>
        this.intersectionObserver.observe(section),
      )
    }
  }

  intersect(entry) {
    if (entry.isIntersecting) {
      entry.target.dataset.briefingTarget = "section visibleSection"
    } else {
      entry.target.dataset.briefingTarget = "section"
    }
    this.dispatch("section-change")
  }
}

class BriefingToc extends Controller {
  static targets = []
  static outlets = ["briefing"]
  static throttles = ["refreshToc"]
  static classCurrent = "-current"

  connect() {
    // By using a debounce, we keep the latest `section-change` event in a timeframe of 10ms.
    // This avoids refreshing the toc too often while providing fast feedback.
    useDebounce(this, { wait: 10 })
  }

  briefingOutletConnected(outlet, element) {
    setTimeout(() => this.refreshToc(), 100)
    element.addEventListener("briefing:section-change", () => this.refreshToc())
  }

  resetToc() {
    this.element
      .querySelectorAll(`.${BriefingToc.classCurrent}`)
      .forEach((element) => {
        element.classList.remove(BriefingToc.classCurrent)
        element.removeAttribute("aria-current")
        element.parentElement.removeAttribute("aria-expanded")
      })
  }

  refreshToc() {
    if (this.hasBriefingOutlet) {
      for (const briefing of this.briefingOutlets) {
        if (briefing.hasVisibleSectionTarget) {
          const firstVisibleSection = briefing.visibleSectionTarget
          const matchingSelector = `a[href="#${CSS.escape(
            firstVisibleSection.id,
          )}"]`
          const matchingTocLink = this.element.querySelector(matchingSelector)

          this.resetToc()
          matchingTocLink.classList.add(BriefingToc.classCurrent)
          matchingTocLink.parentElement.classList.add(BriefingToc.classCurrent)
          matchingTocLink.setAttribute("aria-current", "true")
          matchingTocLink.parentElement.setAttribute("aria-expanded", "true")
          history.replaceState(null, "", location.pathname + "#" + matchingTocLink.getAttribute("href").slice(1))

          break
        }
      }
    }
  }

  goToAnchor(event) {
    event.preventDefault()

    const anchorName = event.target.getAttribute("href").slice(1)
    const anchor = document.getElementById(anchorName) || document.body

    anchor.scrollIntoView({ behavior: "smooth", block: "start" })
    history.replaceState(null, "", location.pathname + "#" + anchorName)
  }
}

class BriefingNav extends Controller {
  static targets = ["editionName"]
  static outlets = ["briefing"]
  static throttles = ["refreshHeader", "toggleVisibility"]
  static values = {
    isSticky: Boolean,
  }

  connect() {
    // By using a debounce, we keep the latest `section-change` event in a timeframe of 10ms.
    // This avoids refreshing the header too often while providing fast feedback.
    useDebounce(this, { wait: 10 })
  }

  briefingOutletConnected(outlet, element) {
    setTimeout(() => this.refreshHeader(), 100)
    element.addEventListener("briefing:section-change", () => this.refreshHeader())
    if (this.isStickyValue) {
      this.toggleVisibility()
      window.addEventListener("scroll", () => this.toggleVisibility())
    }
  }

  toggleVisibility() {
    this.element.classList.toggle("-hidden", window.scrollY < 10)
  }

  refreshHeader() {
    if (this.hasBriefingOutlet) {
      for (const briefing of this.briefingOutlets) {
        if (briefing.hasVisibleSectionTarget) {
          const firstVisibleSection = briefing.visibleSectionTarget
          const matchingSelector = `a[href="#${CSS.escape(
            firstVisibleSection.id.split("--")[0],
          )}"]`
          const matchingTocLink = this.element.querySelector(matchingSelector)
          this.editionNameTarget.textContent = matchingTocLink.textContent

          break
        }
      }
    }
  }

  goToAnchor(event) {
    event.preventDefault()

    const anchorName = event.target.getAttribute("href").slice(1)
    const anchor = document.getElementById(anchorName) || document.body

    anchor.scrollIntoView({ behavior: "smooth", block: "start" })
  }
}

class BriefingDatepicker extends Controller {
  showPicker(event) {
    try {
      event.target.showPicker()
    } catch { }
  }

  updateDate(event) {
    event.target.form.submit()
  }
}

class BriefitemToolbar extends Controller {
  static targets = ["mailto"]

  async openMailto(e) {
    e.preventDefault()
    const data = await fetchUrl(e.params.url)
    this.mailtoTarget.href = data.mailto
    this.mailtoTarget.click()
    this.mailtoTarget.removeAttribute("data-action")
  }
}

class BriefitemSummaries extends Controller {
  static targets = ["container", "summary", "source"]

  static selectorMainColumn = ".main-column"
  static selectorBriefitem = ".briefitem-newscard"
  static selectorBriefitemIdHolder = "[data-briefitem-id]"
  static classStrong = "-strong"
  static classActive = "-active"
  static classHidden = "-hidden"

  connect() {
    this.initializeBriefitemsLinks()
    this.initializeSummaries()
  }

  initializeBriefitemsLinks() {
    this.sourceTargets.forEach((source) => {
      const { briefitemId } = source.dataset
      const links = this._getMatchingBriefitemLinks(briefitemId, source.getAttribute("href"))
      links.forEach((link) => {
        link.classList.add(BriefitemSummaries.classStrong)
      })
    })
  }

  initializeSummaries() {
    // Sort summaries by visual order
    const sortedSummaries = orderBy(
      this.summaryTargets,
      [(summary) => {
        const { briefitemId } = summary.dataset
        return this._getBriefitemTopPosition(briefitemId)
      }],
      ["asc"])

    // Initialize sorted summaries
    sortedSummaries.forEach((summary) => {
      const { briefitemId } = summary.dataset
      // Set summary top position
      const briefitemTop = this._getBriefitemTopPosition(briefitemId)
      summary.style.top = `${briefitemTop}px`
      summary.classList.remove(BriefitemSummaries.classHidden)
      // Set briefitem min height (to avoid overlaps between summaries)
      this._setBriefitemMinHeight(briefitemId, summary.offsetHeight)
    })
  }

  onMouseOver({ detail: { content: { preview, target } } }) {
    const links = this._getMatchingLinks(target)
    links.forEach((link) => {
      link.classList.add(BriefitemSummaries.classActive)
    })
  }

  onMouseOut({ detail: { content: { preview, target } } }) {
    const links = this._getMatchingLinks(target)
    links.forEach((link) => {
      link.classList.remove(BriefitemSummaries.classActive)
    })
  }

  // Helper methods

  _getBriefitemTopPosition(briefitemId) {
    const briefitem = document.getElementById(`briefitem-${briefitemId}`)
    if (!briefitem) return 0

    const briefitemRect = briefitem.getBoundingClientRect()
    const mainColumn = briefitem.closest(BriefitemSummaries.selectorMainColumn)
    const mainColumnRect = mainColumn.getBoundingClientRect()
    // TODO: Kill briefitem annoying negative margin (when scroll-padding-top is properly supported on Safari)
    const briefitemNegativeMargin = parseInt(getComputedStyle(briefitem).marginTop)
    const briefitemTop = briefitemRect.top - mainColumnRect.top - briefitemNegativeMargin
    return briefitemTop
  }

  _setBriefitemMinHeight(briefitemId, minHeight) {
    const briefitem = document.getElementById(`briefitem-${briefitemId}`)
    if (!briefitem) return

    const briefitemNegativeMargin = parseInt(getComputedStyle(briefitem).marginTop)
    const briefitemHeight = briefitem.offsetHeight + briefitemNegativeMargin
    if ((briefitemHeight < minHeight) && !isMobile()) {
      briefitem.style.minHeight = `${minHeight - briefitemNegativeMargin}px`
    } else {
      briefitem.style.minHeight = ""
    }
  }

  _getMatchingLinks(target) {
    const href = target.getAttribute("href")
    const briefitemId = this._getBriefitemIdFromLink(target)
    return Array.from(document.querySelectorAll(`a[href="${CSS.escape(href)}"]`)).filter(link => this._getBriefitemIdFromLink(link) === briefitemId)
  }

  _getMatchingBriefitemLinks(briefitemId, href) {
    const briefitem = document.getElementById(`briefitem-${briefitemId}`)
    if (!briefitem) return []

    return Array.from(briefitem.querySelectorAll(`a[href="${CSS.escape(href)}"]`))
  }

  _getBriefitemIdFromLink(link) {
    return link.closest(BriefitemSummaries.selectorBriefitemIdHolder)?.dataset?.briefitemId
  }
}

export { Briefing, BriefingDatepicker, BriefingNav, Briefings, BriefingToc, BriefitemSummaries, BriefitemToolbar }
